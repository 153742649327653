<template>
  <div>
    <v-card flat tile>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-card class="mx-6" flat>
        <v-row class="pt-6 mb-0">
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              readonly
              label="Fecha"
              v-model="datosGuia.FECHA"
            ></v-text-field
          ></v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              label="Cliente"
              readonly
              v-model="datosGuia.CLIENTE"
            ></v-text-field
          ></v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              label="Notify"
              readonly
              v-model="datosGuia.NOTIFY_NOMBRE"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="4" class="py-0"
            ><v-text-field
              class="my-0"
              label="Estado del vuelo"
              readonly
              v-model="datosGuia.NUM_VUELO"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              label="Total cajas full"
              readonly
              v-model="datosGuia.NUM_REAL_CAJAS"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              label="Total de piezas"
              readonly
              v-model="datosGuia.TOTAL_PIEZAS"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="4" class="py-0"
            ><v-text-field
              class="my-0"
              label="Medio de transporte"
              readonly
              v-model="datosGuia.MEDIO_TRANSPORTE"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              label="Estado del vuelo"
              readonly
              v-model="datosGuia.VUELO_NUM"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              class="my-0"
              label="Tipo de bienes"
              readonly
              v-model="tipoBienes"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-toolbar flat color="white">
        <v-btn small color="info" class="mx-2" @click="agregarCoordinacion"
          ><v-icon left>mdi-plus</v-icon>Crear</v-btn
        >
        <v-btn small color="info" class="mx-2"
          ><v-icon left>mdi-delete</v-icon>Borrar</v-btn
        >

        <v-col cols="3" class="pb-0 mb-0">
          <v-file-input
            class="pb-0 mb-0"
            label="Iportar desde archivo"
            v-model="archivoImp"
          ></v-file-input>
        </v-col>
        <v-col cols="3">
          <v-btn small color="info" class="mx-2" @click="importar()"
            ><v-icon left>mdi-plus</v-icon>Importar</v-btn
          >
        </v-col>
        <!-- <v-btn small color="info" class="mx-2"
          ><v-icon left>mdi-check</v-icon>Boton 3</v-btn
        > -->
        <!-- <v-btn small color="info" class="mx-2"
          ><v-icon left>mdi-cogs</v-icon>Boton 4</v-btn
        > -->
      </v-toolbar>

      <v-data-table
        id="detalleCoord"
        :headers="headerDetalle"
        :options.sync="options"
        :items="detalleGuia"
        :itemsPerPage="itemsPerPage"
        class="elevation-0 mx-1"
        show-select
        show-expand
        :single-expand="true"
        :single-select="true"
        v-model="seleccion"
        item-key="DETALLE_ID"
        hide-default-footer
      >
        <template v-slot:body.prepend>
          <tr>
            <td></td>
            <td></td>
            <td>
              <v-text-field
                small
                v-model="searchFinca"
                type="text"
                label="Finca"
                class=""
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                small
                v-model="searchComer"
                type="text"
                label="Comer"
              ></v-text-field>
            </td>
            <td></td>
            <td>
              <v-text-field
                small
                v-model="searchOrden"
                type="text"
                label="Orden"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                small
                v-model="searchHawb"
                type="text"
                label="Hawb"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                small
                v-model="searchCliente"
                type="text"
                label="Cliente"
              ></v-text-field>
            </td>
            <td></td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12" class="my-2">
                <v-alert type="success" dense class="mb-0 py-1">
                  Datos de la reserva
                </v-alert>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Piezas</th>
                        <th class="text-center">Full</th>
                        <th class="text-center">Medio</th>
                        <th class="text-center">Tercio</th>
                        <th class="text-center">Cuarto</th>
                        <th class="text-center">Sexto</th>
                        <th class="text-center">Octavo</th>
                        <th class="text-center">Diecis.</th>
                        <th class="text-center">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="pieza in item.piezas" :key="item.DETALLE_ID">
                        <td class="text-center">{{ item.PIEZAS_CONF }}</td>
                        <td class="text-center">
                          {{ parseFloat(pieza.fulles).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(pieza.mitades).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(pieza.tercios).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(pieza.cuartos).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(pieza.sextos).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(pieza.octavos).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(pieza.dieciseisavos).toFixed() }}
                        </td>

                        <td class="text-center">
                          <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mr-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="cargarReserva(pieza)"
                                color="info"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <span>Editar reserva</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="cancelar(reserva)"
                                color="error"
                                >mdi-cancel</v-icon
                              >
                            </template>
                            <span>Cancelar reserva</span>
                          </v-tooltip> -->
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </td>
        </template>

        <!-- <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template> -->
      </v-data-table>
    </v-card>

    <!-- Dialog ingreso coordinación -->
    <v-dialog v-model="dialogIngresoCoord" persistent max-width="660px">
      <v-card>
        <v-alert color="blue" dark height="40" class="py-0">
          <v-card-title class="py-1">
            <span class="headline"
              ><v-icon icon="fa:fas fa-search"></v-icon>Coordinar</span
            >
          </v-card-title>
        </v-alert>

        <v-card-text>
          <v-container>
            <v-form ref="formIngCoord">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    v-model="datosCoord.FINCA_ID"
                    small
                    item-text="FINCA_NOMBRE"
                    item-value="ENTE_ID"
                    :items="fincas"
                    clearable
                    label="Seleccione una finca"
                    @change="cargarDaesFinca(datosCoord.FINCA_ID)"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.FINCA_NOMBRE"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    :rules="_fechaDae"
                    v-model="datosCoord.FUE_ID"
                    small
                    item-text="DAE_DEST"
                    item-value="FUE_ID"
                    :items="daes"
                    clearable
                    label="Seleccione una DAE"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.FUE"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.DESTINO"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="px-2 py-5" cols="12">
                  <v-label class="px-12 py-4">Variedades</v-label>
                  <span v-for="i in datosCoord.VARIEDADES">
                    <v-chip
                      small
                      class="ma-1"
                      :color="colorVariedad(i.VALOR_PARAM)"
                    >
                      {{ i.VALOR_PARAM }}
                    </v-chip>
                  </span>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col class="pt-0 pb-0" cols="1.5">
                  <v-text-field v-model="datosCoord.fulles" label="Fulles">
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="1.5">
                  <v-text-field v-model="datosCoord.mitades" label="Mitades">
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="1.5">
                  <v-text-field v-model="datosCoord.tercios" label="Tercios">
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="1.5">
                  <v-text-field v-model="datosCoord.cuartos" label="Cuartos">
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="1.5">
                  <v-text-field v-model="datosCoord.sextos" label="Sextos">
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="1.5">
                  <v-text-field v-model="datosCoord.octavos" label="Octavos">
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="2">
                  <v-text-field
                    v-model="datosCoord.dieciseisavos"
                    label="Dieciseisavos"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="datosCoord.SCLIENTE_ID"
                    small
                    item-text="SCLI_NOMBRE"
                    item-value="ENTE_ID"
                    :items="subClientes"
                    clearable
                    label="Seleccione un cliente"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.SCLI_NOMBRE"
                          ></v-list-item-title>
                          <!-- <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle> -->
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-textarea
                    rows="2"
                    v-model="datosCoord.REMARKS"
                    label="Remarks"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-textarea
                    rows="2"
                    v-model="datosCoord.OBSERVACIONES"
                    label="Observaciones"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn color="info" small @click="guardarCoord()">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn small @click="dialogIngresoCoord = false">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
#detalleCoord td,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 0.6rem !important;
}
#detalleCoord .v-text-field__slot > .v-label {
  font-size: 0.7rem !important;
}

input#input-83,
input#input-86,
input#input-89,
input#input-92,
input#input-95 {
  font-size: 0.74rem !important;
  padding-top: 2px;
}
#detalleCoord .v-data-table-header th {
  white-space: nowrap;
  text-align-last: center;
}

#detalleCoord .v-text-field--is-booted {
  padding-top: 6px;
  margin-top: 4px;
  padding-bottom: 0px;
}

#detalleCoord .v-text-field__details > .v-messages {
  padding: 0;
  margin: 0;
}

#detalleCoord th,
.v-data-table-header {
  background-color: #eceaea;
  border: thin solid #cecece;
}
#detalleCoord.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 0.75rem;
  height: 30px;
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";
import axios from "axios";

export default {
  name: "ComponentName",
  components: {},
  props: {},
  data: () => ({
    header: [
      { text: "FINCA", value: "FINCA_NOMBRE" },
      { text: "COMERCIALIZADOR", value: "COMER_NOMBRE" },
      { text: "CAJAS", value: "BOXES_COORD" },
      { text: "ORDEN DE EMBARQUE", value: "FUE", width: 80 },
      { text: "HAWB", value: "NUM_GUIA_FINCA" },
      { text: "CLIENTE", value: "SCLIENTE_NOMBRE" },
      { text: "OBSERVACIONES", value: "OBSERVACIONES", width: 120 },
    ],
    overlay: false,
    searchFinca: "",
    searchCliente: "",
    searchOrden: "",
    searchComer: "",
    searchHawb: "",
    datosGuia: {},
    datosCoord: {
      FUE_ID: "",
      AWB: "",
      OBSERVACIONES: "",
      fulles: 0,
      mitades: 0,
      cuartos: 0,
      tercios: 0,
      sextos: 0,
      octavos: 0,
      dieciseisavos: 0,

      CABECERA_ID: "",
      FINCA_ID: "",
      HCOORD_ID: "",
      CNEE_ID: "",
      RATE: "",
      FECHA: "",
      COMER_ID: "",
      SCLIENTE_ID: "",
    },
    headerPiezas: [],
    options: {},
    detalleGuia: [],
    listaAutocomplete: [],
    seleccion: [],
    itemsPerPage: 550,
    totalItems: 0,
    currentPage: 1,
    pageCount: 0,
    dialogIngresoCoord: false,
    daes: [],
    fincas: [],
    subClientes: [],
    archivoImp: "",
  }),
  computed: {
    ...mapState("master", [
      "loadingBtn",
      "loadingTable",
      "user",
      "token",
      "url",
    ]),
    headerDetalle() {
      return [
        {
          text: "FINCA",
          value: "FINCA_NOMBRE",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["searchFinca"].toLowerCase());
          },
        },
        {
          text: "COMERCIALIZADOR",
          value: "COMER_NOMBRE",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["searchComer"].toLowerCase());
          },
        },
        { text: "CAJAS", value: "BOXES_COORD" },
        {
          text: "ORDEN DE EMBARQUE",
          value: "FUE",
          width: 80,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["searchOrden"].toLowerCase());
          },
        },
        {
          text: "HAWB",
          value: "NUM_GUIA_FINCA",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["searchHawb"].toLowerCase());
          },
        },
        {
          text: "CLIENTE",
          value: "SCLIENTE_NOMBRE",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["searchCliente"].toLowerCase());
          },
        },
        { text: "OBSERVACIONES", value: "OBSERVACIONES", width: 120 },
      ];
    },
    estadoGuia() {
      if (this.datosGuia.AWB_ESTADO == 0) {
        return "ABIERTA";
      }
      if (this.datosGuia.AWB_ESTADO == 4) {
        return "CERRADA";
      }
    },
    tipoBienes() {
      if (this.datosGuia.IDENT_BIENES == "FF") {
        return "SIMPLE";
      }
      if (this.datosGuia.IDENT_BIENES == "CONS") {
        return "CONSOLIDADO";
      }
    },
    _fechaDae() {
      let rf = this.datosGuia.FECHA;

      if (this.datosCoord.FUE_ID == "" || this.datosCoord.FUE_ID == null) {
        if (
          !(
            this.datosCoord.AWB.includes("EZF") === true ||
            this.datosCoord.AWB.includes("FWC") === true ||
            this.datosCoord.AWB.includes("MAR") === true
          )
        )
          return [false || "Seleccione una DAE "];
      }

      for (let i = 0; i < this.daes.length; i++) {
        if (this.daes[i].FUE_ID == this.datosCoord.FUE_ID) {
          if (
            !moment(rf).isBetween(
              moment(this.daes[i].FECHA_INICIO),
              moment(this.daes[i].FECHA_CADUCA),
              undefined,
              "[]"
            )
          ) {
            return [
              false ||
                "La DAE seleccionada no se puede utilizar para esta fecha de vuelo",
            ];
          }
        }
      }
    },
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
      "setDrawer",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    filtroDatos() {
      return this.detalleGuia;
    },
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/coordinar-guia-aerea");
      this.requestApi({
        method: "GET",
        data: { awb: this.$route.params.awb },
      })
        .then((res) => {
          //console.log(res.data);
          this.detalleGuia = res.data.detalle;
          this.datosGuia = res.data.cabecera[0];
          this.fincas = res.data.fincas;
          this.subClientes = res.data.clientes;
          this.setLoadingTable(false);
          this.setTitleToolbar(
            "COORDINACION GUIA# " + this.datosGuia.AWB + " - " + this.estadoGuia
          );
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
          //this.setTitleToolbar("COORDINACION GUIA# " + this.$route.params.awb);
        });
    },
    guardarCoord() {
      if (!this.$refs.formIngCoord.validate()) {
        return false;
      }
      this.dialogIngresoCoord = false;

      this.setLoadingTable(true);
      this.setUrl("api/ingresar-coordinacion");
      this.requestApi({
        method: "POST",
        data: this.datosCoord,
      })
        .then((res) => {
          //console.log(res.data);
          /*this.detalleGuia = res.data.detalle;
          this.datosGuia = res.data.cabecera[0];
          this.fincas = res.data.fincas;
          this.subClientes = res.data.clientes;*/
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.cargarDatos();
          this.setLoadingTable(false);
        });
    },
    agregarCoordinacion() {
      if (this.estadoGuia != "ABIERTA") {
        this.alertNotification({
          param: {
            html: "No se puede coordinar en esta guía",
            timer: 3000,
            icon: "error",
            title: "Error",
          },
        });
        return false;
      }
      console.log(this.datosGuia);
      this.datosCoord = {
        AWB: this.datosGuia.AWB,
        CABECERA_ID: this.datosGuia.CABECERA_ID,
        HCOORD_ID: this.datosGuia.HCOORD_ID,
        CNEE_ID: this.datosGuia.CLIENTE_ID,
        RATE: this.datosGuia.RECARGO_COMBUS,
        OBSERVACIONES: "",
        COMER_ID: "",
        SCLIENTE_ID: "",
        fulles: 0,
        mitades: 0,
        cuartos: 0,
        tercios: 0,
        sextos: 0,
        octavos: 0,
        dieciseisavos: 0,
      };
      this.dialogIngresoCoord = true;
    },
    cargarLista() {},
    handlePageChange() {
      this.cargarLista();
    },
    cargarDaesFinca(fincaId) {
      this.setUrl("api/daes-vigentes-finca?fincaId=" + fincaId);
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        //console.log(res);
        this.daes = res.data.daes;
        //this.cargarGuias();
      });
    },

    importar() {
      /*this.setUrl("api/importar-coordinacion");
      this.requestApi({
        method: "POST",
        data: {
          cabecera_id: this.datosGuia.CABECERA_ID,
          cliente_id: this.datosGuia.CLIENTE_ID,
          awb: this.datosGuia.AWB,
        },
      }).then((res) => {
        //console.log(res);
        this.daes = res.data.daes;
        //this.cargarGuias();
      });*/

      //////
      let formData = new FormData();
      formData.append("archivo", this.archivoImp);
      formData.append("user_name", this.user.user_login_id);
      formData.append("cabecera_id", this.datosGuia.CABECERA_ID);
      formData.append("cliente_id", this.datosGuia.CLIENTE_ID);
      formData.append("awb", this.datosGuia.AWB);
      //console.log(this.archivoSelecionados);
      this.overlay = true;
      this.setUrl("api/importar-coordinacion");
      //alert(this.token);

      axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.overlay = false;

          //this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
          this.alertNotification({
            param: {
              html: res.data.msg,
            },
          });
        })
        .catch((err) => {
          this.overlay = false;
          this.alertNotification({
            param: {
              html: err.data.msg,
            },
          });
        })
        .finally((err) => {
          this.overlay = false;
        });

      ///////
    },
  },
  mounted() {
    this.cargarDatos();
    this.setTitleToolbar("COORDINACION GUIA# " + this.$route.params.awb);
    this.setDrawer(false);
  },
  watch: {
    "datosGuia.AWB_ESTADO": function (val) {
      // if (this.datosGuia.AWB_ESTADO == "0") {
      //   this.datosGuia.ESTADO = "ABIERTA";
      // }
    },

    "datosCoord.FINCA_ID": function (val) {
      // if (this.datosGuia.AWB_ESTADO == "0") {
      this.datosCoord.COMER_ID = val;
      // }
    },
  },
};
</script>
